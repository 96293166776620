import React from 'react';
import { Box, useColorModeValue, useToken } from '@chakra-ui/react';
import { OrganizationJsonLd } from 'next-seo';
import { useAuthState } from '../contexts/AuthContext';
import { HeroCard } from '../components/LandingPage/HeroCard';
import Loading from '../components/Loading';
import Features from '../components/LandingPage/Features';
import Page from '../components/Page';
import OnboardingSteps from '../components/LandingPage/OnboardingSteps';
import { BenefitsFeatures } from '../components/LandingPage/BenefitsFeatures';
import { DeveloperFeatures } from '../components/LandingPage/DeveloperFeatures';
import FAQs from '../components/LandingPage/FAQs';
import PricingCards from '../components/LandingPage/PricingCards';

export default function Home() {
  const { isLoading } = useAuthState();
  const [bgDefault, lightEnd, darkEnd] = useToken('colors', ['bg-surface', 'whiteAlpha.800', 'blackAlpha.300']);
  const shadowStyle = useColorModeValue(
    '23px 23px 46px #c9ccd0, -23px -23px 46px #fcfcfc',
    '23px 23px 46px #12141c, -23px -23px 46px #181c26'
  );
  const bgStyle = useColorModeValue(
    `linear-gradient(145deg, ${bgDefault}, ${lightEnd})`,
    `linear-gradient(145deg, ${bgDefault}, ${darkEnd})`
  );

  if (isLoading) {
    <Loading />;
  }

  return (
    <Page
      seoOptions={{
        title: 'CronPay | Building subscription businesses made easy',
        description:
          'CronPay features a host of specialised payment solutions for subscription businesses. Highly reliable, fault tolerant and intuitive to use.',
        canonical: 'https://cronpay.io/'
      }}
    >
      <OrganizationJsonLd
        name='CronPay'
        legalName='Simply Anvil (Pty) Ltd'
        url='https://cronpay.io/'
        logo='https://cronpay.io/images/cronpay-logo-600.png'
        sameAs={['https://cronpay.io']}
        contactPoint={[
          {
            contactType: 'info',
            email: 'info@simplyanvil.com',
            availableLanguage: ['English', 'Afrikaans']
          }
        ]}
      />

      <HeroCard pb={{ base: 0, md: 20 }} pt={{ base: 4, md: 20 }} />

      <Box>
        <Box mx='auto' textAlign='left'>
          <Box id='features' />
          <Features />

          <OnboardingSteps />

          <BenefitsFeatures id='benefits' my={20} py={20} cardBg={bgStyle} cardShadow={shadowStyle} />

          <DeveloperFeatures my={20} cardBg={bgStyle} cardShadow={shadowStyle} />

          {/* Calculator */}
          {/* <Calculator mt={20} maxW='4xl' mx='auto' id='calculator' mb={40} /> */}

          {/* Pricing cards */}
          <Box id='pricing' />
          <PricingCards cardBg={bgStyle} cardShadow={shadowStyle} signingUp />

          {/* Frequently asked questions */}
          <FAQs />

          {/* <CallToAction /> */}
        </Box>
      </Box>
    </Page>
  );
}
