import { BoxProps, Button, Flex, Heading, Text, VStack } from '@chakra-ui/react';
import Link from 'next/link';
import * as React from 'react';

export function HeroCard({ ...props }: BoxProps) {
  return (
    <Flex
      as='section'
      align='flex-start'
      direction={{ base: 'column', lg: 'row' }}
      justify='space-between'
      my={{ base: 4, md: 12 }}
      {...props}
    >
      <VStack w='100%'>
        <Heading as='h1' size={{ base: 'md', md: 'xl' }} mb={{ base: 4, md: 6 }} fontWeight='extrabold'>
          Subscription payments made simple
          <Text as='span' color='brand.500'>
            .
          </Text>
        </Heading>
        <Text fontWeight='bold'>Quickstart your subscription business with CronPay</Text>
        <Text size='xs'>Already, more than 4000 subscriptions charged.</Text>
        <Button
          as={Link}
          href='/sign-up/'
          mt='8'
          minW='14rem'
          colorScheme='brand'
          size='lg'
          height='14'
          px='8'
          fontSize='md'
          fontWeight='bold'
          bgColor='brand.500'
        >
          Create your FREE account now
        </Button>
      </VStack>
    </Flex>
  );
}
