/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, Heading, HStack, List, ListIcon, ListItem, Text, useColorModeValue } from '@chakra-ui/react';
import { BsFillQuestionSquareFill } from 'react-icons/bs';
import { FAQPageJsonLd } from 'next-seo';

interface FAQsProps {}

const FAQs: FC<FAQsProps> = React.memo(() => {
  const faqs = [
    {
      question: 'What is CronPay?',
      answer:
        'CronPay is a specialised payment solution for subscription businesses. It simplifies subscription management, allowing businesses to automate billing, accept payments, manage customer accounts, view insights, and streamline the entire payment process.'
    },
    {
      question: 'How can I start using CronPay for my subscription business?',
      answer:
        'To start using CronPay, sign up on their platform, link your preferred payment gateway, add your first plan, copy the subscription link, and start accepting subscription payments.'
    },
    {
      question: 'What types of billing options does CronPay offer?',
      answer: 'CronPay offers flexible billing options, including daily, monthly and annual plan intervals.'
    },
    {
      question: 'What Bring Your Own Gateways (BYOG) does CronPay support?',
      answer:
        'CronPay supports Stripe, Payfast and Paystack; And we are actively building out support for others. If you are looking for a specific Payment Gateway, feel free to contact us regarding the roadmap.'
    },
    {
      question: 'Does CronPay provide automated notifications and retries for payments?',
      answer:
        'Yes, CronPay maximises revenue by alerting customers of expired cards and automated retries on failed payments.'
    },
    {
      question: 'Can customers manage their subscriptions directly?',
      answer:
        'Customers have direct access to an online portal where they can pause or cancel their subscriptions anytime.'
    },
    {
      question: 'Is it possible to customise branding and communications with CronPay?',
      answer:
        'CronPay lets you set up your KYC pages and customise your emails with your company brand, offering your customers a fully branded experience.'
    },
    {
      question: 'How do Test and Live organisations work?',
      answer:
        "CronPay allows a user to create a Test or Live organisation. Test organisation will automatically integrate with the test environments and sandbox of the Payment Gateway. An organisation can quickly be upgraded to one of CronPay's affordable live plans."
    }
  ];

  return (
    <Box id='faqs' my={20}>
      <FAQPageJsonLd
        mainEntity={faqs.map((faq) => ({
          questionName: faq.question,
          acceptedAnswerText: faq.answer
        }))}
      />

      <Heading as='h2' size='3xl' mb={12} fontWeight='extrabold' textAlign='center'>
        Frequently Asked Questions
      </Heading>
      <Box>
        <List spacing={6} fontSize='lg' maxW='4xl' mx='auto'>
          {faqs.map((faq) => (
            <ListItem key={faq.question}>
              <HStack spacing={2} mb={0}>
                <ListIcon as={BsFillQuestionSquareFill} color={useColorModeValue('brand.600', 'brand.300')} />
                <Heading as='h3' size='xs' fontSize='xl' fontWeight='bold'>
                  {faq.question}
                </Heading>
              </HStack>
              <Text ml={9} fontSize='lg' color={useColorModeValue('gray.800', 'gray.300')}>
                {faq.answer}
              </Text>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
});

FAQs.displayName = 'FAQs';
export default FAQs;
