/* eslint-disable max-len */
import React, { FC } from 'react';
import { Box, BoxProps, chakra } from '@chakra-ui/react';

const Ribbon: FC<BoxProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <chakra.svg
        display='block'
        width='258.480566px'
        height='246.039119px'
        viewBox='0 0 258.480566 246.039119'
        xmlns='http://www.w3.org/2000/svg'
        preserveAspectRatio='none'
      >
        <defs>
          <linearGradient x1='87.3384379%' y1='77.033956%' x2='87.3384379%' y2='11.3594938%' id='linearGradient-1'>
            <stop stopColor='#44306E' offset='0%' />
            <stop stopColor='#715B95' offset='39.7928398%' />
            <stop stopColor='#7584AF' offset='100%' />
          </linearGradient>
          <linearGradient x1='50%' y1='86.4201451%' x2='50%' y2='12.9826647%' id='linearGradient-2'>
            <stop stopColor='#5B4983' offset='0%' />
            <stop stopColor='#7A7AA8' offset='45.7404752%' />
            <stop stopColor='#7A86B0' offset='100%' />
          </linearGradient>
          <linearGradient x1='21.8300175%' y1='61.5417265%' x2='107.21133%' y2='60.2572204%' id='linearGradient-3'>
            <stop stopColor='#773A82' offset='0%' />
            <stop stopColor='#73BFCE' offset='100%' />
          </linearGradient>
          <linearGradient x1='5.58080817%' y1='59.0202246%' x2='96.7447281%' y2='59.0202246%' id='linearGradient-4'>
            <stop stopColor='#7C4084' offset='0%' />
            <stop stopColor='#74C3D1' offset='100%' />
          </linearGradient>
        </defs>
        <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
          <g id='Artboard' transform='translate(-482.0681, -211)'>
            <g id='Group' transform='translate(482.0681, 211)'>
              <path
                d='M143.931893,0 C176.671508,17.9932322 192.133679,46.1719886 190.318405,84.536269 C188.503131,122.90055 180.72033,150.899636 166.970003,168.53353 L247.522242,164.677178 L258.104327,135.455124 L258.104327,63.4060469 C258.899348,45.5315443 254.191163,30.7310748 243.979775,19.0046384 C233.768386,7.27820199 220.357516,0.943322526 203.747166,0 L143.931893,0 Z'
                id='Path-3'
                fill='url(#linearGradient-1)'
              />
              <path
                d='M143.931893,0 C175.329933,16.6532422 191.028953,45.3359272 191.028953,86.0480552 C191.028953,102.480676 186.545194,120.5 179.848711,145.720525 C175.384389,162.534208 170.071563,169.853588 163.910233,167.678664 L208.739122,164.655456 C215.95611,142.396086 220.454093,119.898242 222.233072,97.1619229 C224.901541,63.0574446 222.096329,54.4911939 211.783138,33.6725789 C204.907678,19.7935023 194.748075,8.5693093 181.304329,0 L143.931893,0 Z'
                id='Path-3-Copy'
                fill='url(#linearGradient-2)'
              />
              <path
                d='M2.27373675e-13,243.17371 C0.991769722,245.314345 2.39635817,246.255591 4.21376535,245.99745 C6.93987611,245.610237 37.1928777,226.577339 46.0546826,223.357038 C54.9164875,220.136737 64.9546853,213.824337 92.6347897,213.824337 C120.314894,213.824337 174.715757,215.644515 196.309679,213.824337 C217.9036,212.00416 223.01659,214.627809 240.123627,199.383468 C251.528318,189.220574 257.647298,175.18822 258.480566,157.286407 L258.480566,90.9188548 C253.495368,108.552126 248.071419,119.966878 242.208719,125.163112 C233.414667,132.957461 221.238902,142.733943 190.433485,145.256778 C159.628069,147.779612 135.648282,146.229909 109.500848,146.229909 C83.3534151,146.229909 74.413654,149.224363 59.33178,160.155982 C44.2499061,171.0876 32.6035508,182.4191 23.0967558,197.012747 C13.5899608,211.606395 1.77488515,237.739453 0.887442574,239.866393 C0.295814191,241.284352 2.27373675e-13,242.386792 2.27373675e-13,243.17371 Z'
                id='Path-2-Copy'
                fill='url(#linearGradient-3)'
              />
              <path
                d='M0,243.17371 C17.9363348,221.479136 29.3797585,208.935566 34.3302711,205.543001 C41.75604,200.454153 60.681399,182.554142 88.3615034,182.554142 C116.041608,182.554142 188.489314,182.554142 200.449516,179.645077 C212.409719,176.736013 224.332046,177.836418 244.857065,160.155982 C251.605492,154.342826 258.382943,146.620573 258.430943,134.476731 C258.462942,126.380836 258.478942,111.861544 258.478942,90.9188548 C253.494827,108.552126 248.071419,119.966878 242.208719,125.163112 C233.414667,132.957461 221.238902,142.733943 190.433485,145.256778 C159.628069,147.779612 136.578496,146.229909 109.500848,146.229909 C82.4232008,146.229909 74.413654,149.224363 59.33178,160.155982 C44.2499061,171.0876 32.6035508,182.4191 23.0967558,197.012747 C13.5899608,211.606395 1.77488515,237.739453 0.887442574,239.866393 C0.295814191,241.284352 0,242.386792 0,243.17371 Z'
                id='Path-2'
                fill='url(#linearGradient-4)'
              />
            </g>
          </g>
        </g>
      </chakra.svg>
    </Box>
  );
};
export default Ribbon;
