import { Box, BoxProps, Container, Heading, Icon, SimpleGrid, Square, Stack, Text } from '@chakra-ui/react';
import * as React from 'react';
import { PiArrowsOutSimpleBold } from 'react-icons/pi';
import { MdOutlineIntegrationInstructions } from 'react-icons/md';
import { TbLock, TbPackageImport } from 'react-icons/tb';
import { VscDebugStepOver } from 'react-icons/vsc';
import { motion } from 'framer-motion';

const features = [
  {
    name: 'Simple',
    description:
      'Use our out-of-the-box payment pages, build your own white labelled pages or integrate with your existing checkout.',
    icon: VscDebugStepOver
  },
  {
    name: 'BYOG',
    description: 'Bring Your Own Gateway - no need to sign up for yet another payments provider',
    icon: TbPackageImport
  },
  {
    name: 'Webhooks',
    description: 'Developer first API and webhooks with excellent documentation and support',
    icon: MdOutlineIntegrationInstructions
  },
  {
    name: 'Grows',
    description: 'Scalable infrastructure that grows with you',
    icon: PiArrowsOutSimpleBold
  },
  {
    name: 'Secure',
    description: 'Advanced security features and data protection',
    icon: TbLock
  }
];

export function DeveloperFeatures({
  cardBg,
  cardShadow,
  ...props
}: { cardBg?: string; cardShadow?: string } & BoxProps) {
  return (
    <Box as='section' position='relative' borderRadius='2xl' my={30} boxShadow={cardShadow} bg={cardBg} {...props}>
      <Box
        as={motion.div}
        initial={{
          opacity: 0,
          background: 'radial-gradient(50px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 80%)'
        }}
        whileInView={{
          opacity: 1,
          background: 'radial-gradient(950px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 80%)'
        }}
        viewport={{ once: true, amount: 0.5 }}
        transition='1s ease-out'
        position='absolute'
        inset='1px'
        borderRadius='2xl'
      />
      <Container py={{ base: '16', md: '24' }}>
        <Stack spacing={{ base: '12', md: '16' }}>
          <Stack spacing={{ base: '4', md: '5' }} maxW='3xl'>
            <Stack spacing='3'>
              <Text fontSize={{ base: 'sm', md: 'md' }} fontWeight='semibold' color='accent'>
                Developer friendly features
              </Text>
              <Heading size={{ base: 'sm', md: 'md' }}>
                Turnkey or customisable, CronPay does it all
                <Text as='span' color='brand.500'>
                  .
                </Text>
              </Heading>
            </Stack>
            <Text color='fg.muted' fontSize={{ base: 'lg', md: 'xl' }}>
              Get started in seconds, configure to fit your exact needs.
            </Text>
          </Stack>
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} columnGap={8} rowGap={{ base: 10, md: 16 }}>
            {features.map((feature) => (
              <Stack key={feature.name} spacing={{ base: '4', md: '5' }}>
                <Square
                  as={motion.div}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  size={{ base: '10', md: '12' }}
                  viewport={{ once: true, amount: 'all' }}
                  bg='accent'
                  color='fg.inverted'
                  borderRadius='lg'
                >
                  <Icon as={feature.icon} boxSize={{ base: '5', md: '6' }} />
                </Square>
                <Stack spacing={{ base: '1', md: '2' }} flex='1'>
                  <Text fontSize={{ base: 'lg', md: 'xl' }} fontWeight='medium'>
                    {feature.name}
                  </Text>
                  <Text color='fg.muted'>{feature.description}</Text>
                </Stack>
                {/* <Button
                  variant='text'
                  colorScheme='blue'
                  rightIcon={<FiArrowRight/>}
                  alignSelf='start'
                >
                  Read more
                </Button> */}
              </Stack>
            ))}
          </SimpleGrid>
        </Stack>
      </Container>
    </Box>
  );
}
