import { BoxProps, Circle, Heading, Stack, Text } from '@chakra-ui/react';

interface StepProps extends BoxProps {
  title: string;
  description: string;
  isCompleted?: boolean;
  isActive?: boolean;
  isLastStep?: boolean;
  index: string;
}

export const Step = ({ isActive, isCompleted, isLastStep, title, description, index, ...props }: StepProps) => {
  return (
    <Stack spacing='4' direction='row' {...props}>
      <Stack align='center'>
        <Circle size='8'>
          <Heading size='sm' {...props}>
            {index}
            <Text as='span' color={isActive ? 'brand.500' : 'bg-step'}>
              .
            </Text>
          </Heading>
        </Circle>
      </Stack>
      <Stack spacing='0.5' pb={isLastStep ? '0' : '8'}>
        <Text
          color='fg.emphasized'
          fontWeight='medium'
          lineHeight={8}
          pb={2}
          // Animation
          display='block'
          position='relative'
          padding='0.2em 0'
          overflow='hidden'
          _after={{
            content: '""',
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100%',
            height: '0.2em',
            backgroundColor: 'brand.500',
            transition: 'opacity 300ms, transform 300ms',
            opacity: 1,
            transform: isActive ? 'translate3d(0, 0, 0)' : 'translate3d(-100%, 0, 0)'
          }}
        >
          {title}
        </Text>
        <Text color='fg.muted'>{description}</Text>
      </Stack>
    </Stack>
  );
};
