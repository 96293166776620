import { Box, BoxProps, Flex, Heading, SimpleGrid, Text, VStack } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import * as React from 'react';

export function BenefitsFeatures({
  cardBg,
  cardShadow,
  ...props
}: { cardBg?: string; cardShadow?: string } & BoxProps) {
  return (
    <Flex
      as='section'
      align='flex-start'
      direction={{ base: 'column', lg: 'row' }}
      justify='space-between'
      my={{ base: 4, md: 12 }}
      {...props}
    >
      <VStack w='100%'>
        <Heading as='h2' size={{ base: 'sm', md: 'lg' }} mb={{ base: 4, md: 10 }} fontWeight='extrabold'>
          Great for you, great for your customers
          <Text as='span' color='brand.500'>
            .
          </Text>
        </Heading>

        <SimpleGrid columns={{ base: 1, md: 4 }} spacing={{ base: 6, md: 6 }} textAlign='left' mb={6}>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg={cardBg || 'bg-surface'}
            borderRadius='lg'
            boxShadow='sm'
            position='relative'
          >
            <Box
              as={motion.div}
              initial={{
                background: 'radial-gradient(0px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 100%)'
              }}
              whileInView={{
                background: 'radial-gradient(250px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 80%)'
              }}
              transition='1s ease-out'
              viewport={{ once: true, amount: 'all' }}
              position='absolute'
              inset='1px'
              borderRadius='md'
              bg='radial-gradient(250px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 80%)'
            />
            <Heading as='h3' size='xs' mb={4}>
              Automated billing and payment processing
            </Heading>
            <Text color='bg-default-inverted'>
              Create subscription from a wide range of flexible options (daily, monthly, yearly).
            </Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg={cardBg || 'bg-surface'}
            borderRadius='lg'
            boxShadow='sm'
            position='relative'
          >
            <Box
              as={motion.div}
              initial={{
                background: 'radial-gradient(0px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 100%)'
              }}
              whileInView={{
                background: 'radial-gradient(250px circle at bottom right, hsla(283, 22%, 54%, 0.15), transparent 80%)'
              }}
              transition='1s ease-out'
              viewport={{ once: true, amount: 'all' }}
              position='absolute'
              inset='1px'
              borderRadius='md'
              bg='radial-gradient(250px circle at right bottom, hsla(283, 22%, 54%, 0.15), transparent 80%)'
            />
            <Heading as='h3' size='xs' mb={4}>
              Automated notifications and retries
            </Heading>
            <Text color='bg-default-inverted'>
              Maximise revenue with advance warning for card expirations and automated retries on failed payments
            </Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg={cardBg || 'bg-surface'}
            borderRadius='lg'
            boxShadow='sm'
            position='relative'
          >
            <Box
              as={motion.div}
              initial={{
                background: 'radial-gradient(0px circle at left top, hsla(283, 22%, 54%, 0.15), transparent 100%)'
              }}
              whileInView={{
                background: 'radial-gradient(250px circle at left bottom, hsla(283, 22%, 54%, 0.15), transparent 80%)'
              }}
              transition='1s ease-out'
              viewport={{ once: true, amount: 'all' }}
              position='absolute'
              inset='1px'
              borderRadius='md'
            />
            <Heading as='h3' size='xs' mb={4}>
              Customer self-service portals
            </Heading>
            <Text color='bg-default-inverted'>
              Customers can pause or cancel subscriptions at any time via direct access to the online portal.
            </Text>
          </Box>
          <Box
            px={{ base: '4', md: '6' }}
            py={{ base: '5', md: '6' }}
            bg={cardBg || 'bg-surface'}
            borderRadius='lg'
            boxShadow='sm'
            position='relative'
          >
            <Box
              as={motion.div}
              initial={{
                background: 'radial-gradient(0 circle at left top, hsla(283, 22%, 54%, 0.15), transparent 100%)'
              }}
              whileInView={{
                background: 'radial-gradient(250px circle at right top, hsla(283, 22%, 54%, 0.15), transparent 80%)'
              }}
              transition='1s ease-out'
              viewport={{ once: true, amount: 'all' }}
              position='absolute'
              inset='1px'
              borderRadius='md'
            />
            <Heading as='h3' size='xs' mb={4}>
              Customizable branding and communications
            </Heading>
            <Text color='bg-default-inverted'>
              Set up your payment page and customise your emails with your own brand.
            </Text>
          </Box>
        </SimpleGrid>

        {/* <Link href='/#calculator'>
          <Button
            mt='8'
            minW='14rem'
            colorScheme='brand'
            size='lg'
            height='14'
            px='8'
            fontSize='md'
            fontWeight='bold'
            bgColor='brand.500'
          >
            Calculate requirements
          </Button>
        </Link> */}
      </VStack>
    </Flex>
  );
}
