import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  Text,
  UnorderedList,
  useColorMode,
  useColorModeValue as mode
} from '@chakra-ui/react';
import React, { FC } from 'react';
import Ribbon from '../Ribbon';

interface FeaturesProps {
  section?: number;
}

const Features: FC<FeaturesProps> = ({ section = 1 }) => {
  const { colorMode } = useColorMode();
  const sections = [
    {
      key: '1',
      title: 'Specialised payment solutions for subscription businesses',
      detail:
        'CronPay takes the hassle out of subscription management so you can focus on growth! Automate billing, accept payments, manage ' +
        'customer accounts, view insights, and streamline the entire payment process.',
      items: []
    }
  ];
  return (
    <Box as='section' mx='auto' py={{ base: '10', md: '20' }} mb={{ base: 0, md: 30 }}>
      <Flex align='flex-start' direction={{ base: 'column', lg: 'row' }} justify='space-between' mb='20'>
        <Box position='relative' mt={10}>
          <Ribbon
            display={{ base: 'none', lg: 'block' }}
            position='absolute'
            top={-110}
            left={-95}
            transform='scale(-0.8)'
            zIndex={0}
          />
          <Ribbon display={{ base: 'none', lg: 'block' }} position='absolute' top={184} right={-85} zIndex={0} />
          <Image
            src={`/images/dashboard-${colorMode}.png`}
            alt='Dashboard'
            w={{ base: '100%', md: '600px' }}
            h={{ base: 'inherit', md: '325px' }}
            position='relative'
            zIndex={1}
          />
        </Box>
        <Box flex='1' maxW={{ lg: 'xl' }} pt='6'>
          <Heading as='h2' size={{ base: 'sm', md: 'md' }} mt='8' fontWeight='extrabold'>
            {sections[section - 1].title}
          </Heading>
          <Text color={mode('blackAlpha.700', 'whiteAlpha.700')} mt='5' fontSize='xl' mb={6}>
            {sections[section - 1].detail}
          </Text>

          <UnorderedList color={mode('gray.600', 'gray.300')} spacing={2}>
            {sections[section - 1].items.map((item) => (
              <ListItem key={item}>{item}</ListItem>
            ))}
          </UnorderedList>
        </Box>
      </Flex>
    </Box>
  );
};

export default Features;
