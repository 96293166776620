import React, { FC } from 'react';
import { Box, Card, Flex, Heading, Stack } from '@chakra-ui/react';
import { Step } from '../Steps/Step';
import { useStep } from '../Steps/useStep';
import Ribbon from '../Ribbon';

interface FeaturesProps {}

export const steps = [
  {
    title: 'Sign up to CronPay in seconds',
    description: ''
  },
  {
    title: 'Link your preferred payment gateway',
    description: ''
  },
  {
    title: 'Add your first plan',
    description: ''
  },
  {
    title: 'Copy the subscription payment link',
    description: ''
  },
  {
    title: 'Get rich!',
    description: ''
  }
];

const typings = ['Sign up', 'Link gateway', 'Add plan', 'Copy link', 'Share & Earn'];

const OnboardingSteps: FC<FeaturesProps> = () => {
  const [currentStep, { setStep }] = useStep({ maxStep: steps.length, initialStep: 0 });

  return (
    <Box as='section' id='steps' mx='auto' py={{ base: '1', md: '12' }} mt={{ base: 10, md: 40 }}>
      <Heading size='md' mb={6}>
        Start accepting subscription payments now
      </Heading>
      <Flex align='flex-start' direction={{ base: 'column', lg: 'row' }} justify='space-between'>
        <Box flex='1' maxW={{ lg: 'xl' }} pt='6'>
          <Stack spacing='0' pb={{ base: 10, md: 40 }}>
            {steps.map((step, id) => (
              <Step
                key={id}
                index={`${id + 1}`}
                cursor='pointer'
                onClick={() => setStep(id)}
                title={step.title}
                description={step.description}
                isActive={currentStep === id}
                isCompleted={false}
                isLastStep={steps.length === id + 1}
              />
            ))}
          </Stack>
        </Box>
        <Box position='relative' mt={{ base: 4, md: 40 }} display={{ base: 'none', md: 'block' }}>
          <Ribbon position='absolute' top={-43} left={-102} zIndex={-1} transform='scale(-0.6, 0.6)' />
          <Ribbon position='absolute' top={-119} right={-112} zIndex={-1} transform='scale(0.4, -0.4)' />
          <Card py={4} px={{ base: 2, md: 40 }}>
            <Heading
              size='md'
              overflow='hidden'
              borderRightWidth='.15em'
              borderColor='brand.500'
              whiteSpace='nowrap'
              mx='auto'
              letterSpacing='.35em'
              animation={
                currentStep % 2 === 0
                  ? 'typing 1.2s steps(30, end),blink-caret .8s step-end infinite'
                  : 'typing-debounce 1.2s steps(30, end),blink-caret .8s step-end infinite'
              }
            >
              {typings[currentStep]}
            </Heading>
          </Card>
        </Box>
      </Flex>
    </Box>
  );
};

export default OnboardingSteps;
